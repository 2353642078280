<template>
  <div>
    <e-sidebar
      :title="edit ? $t('Editar Unidade') : $t('Nova Unidade')"
      :show="showSidebar"
      :fetching="fetching"
      :saving="saving"
      width="500px"
      @save="saveUnit"
      @hidden="cleanLocalData"
    >
      <template #content>
        <div>
          <FormulateForm
            ref="unitForm"
            name="form"
          >
            <FormulateInput
              v-if="edit"
              id="product-unit-id"
              v-model="localUnit.id"
              type="label"
              :label="$t('Sigla')"
            />
            <FormulateInput
              v-else
              id="product-unit-id"
              v-model="localUnit.id"
              :is-edit="false"
              type="text"
              class="required"
              :label="$t('Sigla')"
              :instruction="$t('Abreviação da unidade. Exemplos: Kg (Quilograma), L (Litros), etc')"
              validation="required"
            />
            <FormulateInput
              id="product-unit-name"
              v-model="localUnit.name"
              type="text"
              class="required"
              :label="$t('Nome')"
              validation="required"
            />
          </FormulateForm>
        </div>
      </template>
    </e-sidebar>
  </div>
</template>

<script>
import { ESidebar } from '@/views/components'
import { mapActions } from 'vuex'

export default {
  components: {
    ESidebar,
  },

  mixins: [],

  props: {
    unitId: {
      type: Number,
      default: null,
    },
    unit: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      localUnitId: null,
      localUnit: {
        id: null,
        name: null,
        active: null,
      },
    }
  },

  watch: {
    unit(val) {
      this.localUnit = val
    },
    unitId(val) {
      this.localUnitId = val
    },
    async localUnitId(val) {
      if (val) {
        try {
          this.fetching = true
          const { data: localUnit } = await this.$http.get(`/api/units/${this.localUnitId}`)
          this.localUnit = localUnit
        } catch (error) {
          this.showGenericError({ error })
        } finally {
          this.fetching = false
        }
      } else {
        this.unit = {}
      }
    },
  },

  methods: {
    ...mapActions('pages/catalog/products', { comboFetchDomainData: 'fetchDomainData' }),

    showCreate() {
      this.localUnit = { name: '', active: true }
      this.edit = false
      this.showSidebar = true
    },

    async show({ id, item } = {}) {
      if (!id && !item?.id) {
        this.showCreate()
      } else {
        if (id) this.localUnitId = id
        if (item) this.localUnit = item
        this.edit = true
        this.showSidebar = true
      }
    },

    async saveUnit() {
      this.$refs.unitForm.showErrors()
      if (this.$refs.unitForm.isValid) {
        try {
          this.saving = true

          const body = {
            id: this.localUnit.id,
            name: this.localUnit.name,
            active: this.localUnit.active,
          }
          const result = await this.$http({
            url: '/api/units',
            method: this.edit ? 'PUT' : 'POST',
            data: body,
          })
          this.comboFetchDomainData('units')
          this.showSuccess({
            message: this.$t(`${this.edit ? 'Atualização' : 'Inclusão'} realizada com sucesso`),
          })
          this.showSidebar = false
          this.$emit('save', result.data)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      } else {
        this.showInvalidDataMessage()
      }
    },

    cleanLocalData() {
      this.showSidebar = false
      this.localUnitId = false
      this.localUnit = {
        id: null,
        name: null,
        active: null,
      }
    },
  },
}
</script>

<style></style>
