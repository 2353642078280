var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("e-sidebar", {
        attrs: {
          title: _vm.edit ? _vm.$t("Editar Unidade") : _vm.$t("Nova Unidade"),
          show: _vm.showSidebar,
          fetching: _vm.fetching,
          saving: _vm.saving,
          width: "500px",
        },
        on: { save: _vm.saveUnit, hidden: _vm.cleanLocalData },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "div",
                  [
                    _c(
                      "FormulateForm",
                      { ref: "unitForm", attrs: { name: "form" } },
                      [
                        _vm.edit
                          ? _c("FormulateInput", {
                              attrs: {
                                id: "product-unit-id",
                                type: "label",
                                label: _vm.$t("Sigla"),
                              },
                              model: {
                                value: _vm.localUnit.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localUnit, "id", $$v)
                                },
                                expression: "localUnit.id",
                              },
                            })
                          : _c("FormulateInput", {
                              staticClass: "required",
                              attrs: {
                                id: "product-unit-id",
                                "is-edit": false,
                                type: "text",
                                label: _vm.$t("Sigla"),
                                instruction: _vm.$t(
                                  "Abreviação da unidade. Exemplos: Kg (Quilograma), L (Litros), etc"
                                ),
                                validation: "required",
                              },
                              model: {
                                value: _vm.localUnit.id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localUnit, "id", $$v)
                                },
                                expression: "localUnit.id",
                              },
                            }),
                        _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "product-unit-name",
                            type: "text",
                            label: _vm.$t("Nome"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.localUnit.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.localUnit, "name", $$v)
                            },
                            expression: "localUnit.name",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }